import React from "react"
import styled from "styled-components"
import slugify from "slugify"

import SEO from "../../components/seo"

import { GridContainer } from "../../components/grid"
import { SimpleTitle } from "../../components/heros"
import { graphql, navigate, PageProps } from "gatsby"
import { BaseContainer } from "../../components/containers"
import { H3 } from "../../components/text"
import { PrimaryButton } from "../../components/buttons"

const StyledSimpleTitle = styled(SimpleTitle)`
  grid-column: 1 / span 12;
`

const CoursesContainer = styled(BaseContainer)`
  grid-column: 1 / span 12;
  display: grid;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
  }
`

const CourseCardContainer = styled.div`
  padding: 2rem;
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  gap: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 0;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
  }
`

interface CourseCardProps {
  productName: string
}

const CourseCard: React.FC<CourseCardProps> = ({ productName }) => {
  const redirectToDetailPage = () => {
    const slug = slugify(productName, { lower: true })
    navigate(`/cursussen/${slug}/`)
  }

  return (
    <CourseCardContainer>
      <div>
        <H3>{productName}</H3>
      </div>
      <div>
        <PrimaryButton onClick={redirectToDetailPage}>Bestellen</PrimaryButton>
      </div>
    </CourseCardContainer>
  )
}

const CoursesOverviewPage: React.FC<PageProps> = ({ data }) => {
  const stripePrices = data as any
  const prices = stripePrices.allStripePrice.nodes

  return (
    <>
      <SEO title="Cursussen" />
      <GridContainer>
        <StyledSimpleTitle>Cursussen</StyledSimpleTitle>
        <CoursesContainer>
          {prices.map((price: any) => {
            return (
              <CourseCard
                key={price.id}
                productName={price.product.name}
              ></CourseCard>
            )
          })}
        </CoursesContainer>
      </GridContainer>
    </>
  )
}

export const query = graphql`
  query StripeCourses {
    allStripePrice(filter: { product: { active: { eq: true } } }) {
      nodes {
        id
        product {
          id
          name
        }
      }
    }
  }
`

export default CoursesOverviewPage
